.slick-prev:before,
.slick-next:before {
  content: none !important;
  @apply !hidden;
}
.carousel {
  @apply flex;
  @apply flex-col;
  .carousel-nav-button {
    @apply block;
    @apply border;
    @apply border-slate-200;
    @apply z-10;
    @apply transition-all;
    @apply duration-300;
    @apply flex;
    @apply items-center;
    @apply justify-center;
    @apply leading-none;
    @apply rounded-full;
    @apply text-slate-400;
    @apply size-11;
    @apply bg-white;

    &:hover {
      @apply bg-slate-100;
      @apply shadow-lg;
    }
  }
  .carousel-controls {
    @apply opacity-50;
    @apply transition-opacity;
    @apply duration-200;
    @apply ease-linear;
    &:hover {
      @apply opacity-100;
    }
  }
  .carousel-controls .carousel-control-button {
    @apply border;
    @apply border-slate-600;
    @apply text-slate-600;
    @apply rounded-full;
    @apply size-11;
    @apply flex;
    @apply justify-center;
    @apply items-center;
    &:hover {
      @apply bg-slate-100;
    }
  }
}
