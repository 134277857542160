.page-layout-home {
  &:after {
    content: none;
    @apply block;
    @apply fixed;
    @apply top-0;
    @apply left-0;
    @apply w-full;
    @apply h-2;
    @apply z-[300];
    @apply bg-black;
    @apply 480:bg-green-500;
    @apply 768:bg-orange-500;
    @apply 1024:bg-blue-500;
    @apply 1200:bg-rose-500;
    @apply 1440:bg-teal-500;
    @apply 1920:bg-violet-500;
    @apply 2560:text-red-500;
  }
  section {
    @apply flex;
    @apply flex-col;
    @apply gap-8;
    @apply max-w-[1440px];
    @apply mx-auto;
    @apply px-4;
    @apply sm:px-6;
    @apply md:px-8;
    @apply w-full;
    @apply my-0;
    .section-header {
      @apply flex flex-col;
      h2 + p {
        @apply mt-1;
      }
      h3 + p {
        @apply mt-1;
      }
    }
  }
  .section-w-full {
    @apply max-w-full;
    @apply px-0;
    @apply sm:px-0;
    @apply md:px-0;
  }
  h1 {
    @apply font-bold;
    @apply text-3xl;
    @apply 480:text-4xl;
    @apply 768:text-5xl;
    @apply leading-tight;
  }
  h1 ~ p {
    @apply text-base;
    @apply 768:text-lg;
    @apply 1024:text-xl;
    @apply 1200:text-2xl;
    @apply leading-snug;
  }
  h1 ~ .subtitle {
    @apply text-xs;
    @apply 480:text-sm;
    @apply 768:text-base;
    @apply leading-tight;
  }
  h2 {
    @apply font-bold;
    @apply text-2xl;
    @apply 480:text-3xl;
    @apply 768:text-4xl;
    @apply leading-tight;
  }
  h3 {
    @apply font-bold;
    @apply text-base;
    @apply 480:text-lg;
    @apply 768:text-xl;
    @apply leading-tight;
  }
  .cursor {
    @apply bg-white;
    @apply inline-block;
    @apply w-1;
    height: 1em;
    margin-left: 3px;
    vertical-align: middle;
  }
}
