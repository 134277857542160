.form-control {
  .form-control-field {
    @apply rounded-md;
    @apply px-2;
    @apply py-2;
    @apply w-full;
  }
  textarea {
    @apply min-h-52;
  }
}
