.project-card {
  @apply bg-slate-300;
  @apply border-slate-100;
  @apply border;
  @apply duration-300;
  @apply inline-flex;
  @apply min-w-60;
  @apply overflow-hidden;
  @apply relative;
  @apply rounded-lg;
  @apply transition-all;
  @apply w-60;
  @apply h-[300px];
  @apply ease-linear;
  @apply shadow-sm;
  &.cursor-pointer:hover {
    @apply border-2;
    @apply border-pink-500;
    box-shadow: 0 0 25px 5px rgb(92, 1, 249);
  }
}
