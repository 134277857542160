@font-face {
  font-family: "PostGrotesk";
  src: url("../fonts/PostGrotesk-Medium.eot"); /* IE9 Compat Modes */
  src: url("../fonts/PostGrotesk-Medium.woff") format("woff"),
    url("../fonts/PostGrotesk-Medium.svg") format("svg");
  font-weight: normal;
}

@font-face {
  font-family: "PostGrotesk";
  src: url("../fonts/PostGrotesk-Bold.eot"); /* IE9 Compat Modes */
  src: url("../fonts/PostGrotesk-Bold.woff") format("woff"),
    url("../fonts/PostGrotesk-Bold.svg") format("svg");
  font-weight: bold;
}
