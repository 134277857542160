.skill-card {
  @apply p-4;
  @apply isolate;
  @apply rounded-xl;
  @apply bg-white/30;
  @apply shadow-lg;
  @apply ring-1;
  @apply ring-black/5;
  @apply max-w-[120px];
  @apply min-w-[120px];
  @apply w-[120px];
  @apply box-content;
}
