@tailwind base;
@tailwind components;
@tailwind utilities;
@import "fonts.css";
@import "theme.css";
@import "general.css";

@layer base {
  body {
    min-width: 320px;
    @apply bg-white;
    @apply text-slate-800;
    @apply scroll-smooth;
  }

  a {
    @apply duration-300;
    @apply transition-all;
    color: var(--color-primary);
    &:hover {
      color: var(--color-primary-darker);
    }
  }
  img {
    @apply max-w-full;
  }
}

@layer components {
  .button {
    font-family: var(--font-primary);
    color: var(--white);

    user-select: none;

    transition: background 0.3s ease-out, color 0.3s ease-out;

    min-width: 10rem;
    @apply inline-flex;
    @apply transform;
    @apply transition-all;
    @apply duration-300;
    @apply text-center;
    @apply justify-center;
    @apply items-center;
    @apply px-4;
    @apply py-2;
    @apply rounded-md;
    @apply font-medium;
  }
  .button:disabled {
    @apply cursor-not-allowed;
    @apply opacity-40;
  }
  .button--compact {
    /* responsive */

    padding: 1rem 1.6rem;

    line-height: 1.6rem;
  }

  .button--primary {
    background-color: var(--color-primary);
  }

  .button--primary:hover {
    color: var(--white);
    background-color: var(--color-primary-darker);
  }

  .button--secondary {
    background-color: var(--white);
    color: var(--black);
  }

  .button--secondary:hover {
    background: rgba(255, 255, 255, 0.85);
  }

  .button__login,
  .button__logout {
    min-width: 8.4rem;

    border: 0.1rem solid var(--indigo);
    color: var(--white);
    background: var(--color-primary);
    width: 50%;

    margin-right: 1.6rem;

    font-family: var(--font-primary);
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 8px;
    border-radius: 3px;
    text-align: center;

    cursor: pointer;
    user-select: none;

    transition: background 0.3s ease-out, color 0.3s ease-out;
    &:hover {
      background-color: var(--color-primary-darker);
    }
  }

  .button__sign-up {
    min-width: 8.4rem;

    border: 0.1rem solid var(--white);
    color: var(--white);
    background-color: transparent;
    width: 50%;

    margin-right: 1.6rem;

    font-style: normal;
    font-weight: 500;
    line-height: 3.2rem;
    padding: 0.8rem 0;
    border-radius: 3px;
    text-align: center;

    cursor: pointer;
    user-select: none;
  }

  .button__login:last-child,
  .button__logout:last-child,
  .button__sign-up:last-child {
    margin-right: 0;
  }

  .button-transparent-white {
    @apply text-white;
    @apply bg-white/10;
    @apply shadow-md;
    @apply border-2;
    @apply border-transparent;
    @apply transform transition duration-500;
    &:hover {
      @apply text-white;
      @apply bg-white/0;
      @apply border-white;
      @apply scale-110;
    }
  }

  .button-sm {
    @apply text-sm;
    @apply px-0;
    @apply py-2;
  }
  .button-xl {
    @apply text-xl;
    @apply px-5;
    @apply py-3;
  }
  .button-2xl {
    @apply text-2xl;
    @apply px-6;
    @apply py-4;
  }
}

.nav-bar {
  @apply px-4;
  @apply mx-auto;
  @apply max-w-[1080px];
}

.nav-bar__container {
  @apply bg-white;
}
.nav-bar__tab {
  @apply text-black;
}
.nav-bar__tab:hover,
.nav-bar__tab:focus,
.nav-bar__tab:active {
  @apply text-black;
}

.mobile-nav-bar__container {
  @apply bg-white;
}

.mobile-nav-bar__toggle {
  @apply text-2xl;
}

.mobile-nav-bar {
  @apply h-14;
}

.mobile-nav-bar__menu {
  @apply top-14;
}

.mobile-nav-bar__tab {
  @apply py-4 px-6;
  @apply text-base;
}

.footer,
.footer a {
  @apply uppercase;
  @apply tracking-wider;
  @apply text-xs;
  @apply text-slate-500;
}

.footer a:hover,
.footer a:focus,
.footer a:active {
  @apply text-slate-800;
}

.page-layout__content {
  @apply mt-0;
  @apply pt-20;
}

@media only screen and (max-width: 640px) {
  .page-layout__content {
    @apply mt-0;
    @apply pt-14;
  }
}

@import "components/index.css";
