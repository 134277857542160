.page-resume {
  &.content-layout {
    @apply px-2;
    @apply 960:px-4;
  }
  .page {
    font-family: var(--font-secondary);
    @apply text-slate-700;
    @apply text-base;
    @apply mx-auto;
    @apply max-w-[960px];
    @apply border;
    @apply border-gray-300;
    @apply rounded-sm;
    @apply bg-white;
    @apply p-[30px];
    @apply text-slate-600;
    @apply font-inter;
    @apply flex;
    @apply flex-col;
    @apply gap-6;
  }
  .tagline {
    @apply text-xl;
    @apply leading-tight;
    @apply font-normal;
  }
  section {
    @apply flex;
    @apply flex-col;
    @apply gap-4;
  }
  h2 {
    @apply font-bold;
    @apply leading-tight;
    @apply text-2xl;
  }
  h3 {
    @apply border-b;
    @apply border-slate-300;
    @apply font-bold;
    @apply text-slate-500;
    @apply tracking-widest;
    @apply uppercase;
  }
  h4 {
    @apply font-bold;
    @apply flex;
    @apply flex-col;
    @apply justify-start;
    @apply 960:flex-row;
    @apply 960:justify-between;
  }
}
